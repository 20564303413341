import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import communication from '../assets/communication.png'
import photo1 from '../assets/BG.png'
import photo2 from '../assets/BG2.png'
import footImage from '../assets/footer.png'
import icon1 from '../assets/instagram.png'
import icon2 from '../assets/mail.png'
import ServicesSection from './ServicesSection'
import dictionary from '../data/dictionary'
function Home() {

  const [clicked, setClicked] = useState(false)
  const [text, setText] = useState(false)
  const handleClick = () => {
    //cuando esta true lo pasa a false y vice versa
    setClicked(!clicked)
  }
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (localStorage.language == 'ES') {
      setText(dictionary.es_ES)
    }else{
      setText(dictionary.en_US)
    }
  });
  
  return (
    <>
      <HomeContainer> 
        <div className="row section1">
          <div className="col">
            <p id="tittle">
              {text.tittle}
            </p>
          </div>
          <div className="col">
            <img id="comm" src={communication} alt="" />
          </div>
        </div>
        <div className="row section2">
          <div>
            <h2 id="offer" style={{textAlign:'center'}}>{text.offer}</h2>
          </div>
          <div className="col-sm-6 mb-3 mb-sm-0 carta1">
            <div className="card">
            <img className="card-img-top" src={photo1} alt="" />
              <div className="card-body">
                <h5 className="card-title titulo">Alpha Core</h5>
                <p className="card-text texto">
                  {text.core}  
                </p>
                <a href="/core" className='link'>{text.info}</a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 carta2" >
            <div className="card">
            <img className="card-img-top" src={photo2} alt="" />
              <div className="card-body">
                <h4 className="card-title titulo">Alpha Consulting</h4>
                <p className="card-text texto">
                  {text.consulting}
                </p>
                <a href="/consulting" className='link'>{text.info}</a>
              </div>
            </div>
          </div>
        </div>
        <ServicesSection/>
        <div className="row section4">
          <div className="col">
            <img id="contact" src={footImage} alt="" />
          </div>
          <div className="col">
            <p id="talk">
              {text.footer}
            </p>
            <p id="insta">
            <img id="instaPhoto" src={icon1} alt="" />
              <a href="https://instagram.com/alphaconduit" target="_blank">@alphaconduit</a>
            </p>
            <p id="mail">
            <img id="mailPhoto" src={icon2} alt="" />
              <a href="mailto:hola@alphaconduit.tech">hola@alphaconduit.tech</a>
            </p>
          </div>
        </div>
      </HomeContainer>
    </>
  )
}

export default Home

const HomeContainer = styled.div`

@media(min-width: 768px){
  width: 99.3%;
  #tittle{
    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 78px;
    line-height: 87px;
    padding-left:80px;
    padding-top:270px;
    padding-bottom:300px;
  }
  #comm{
    width:932px;
    height:623px;
    position:absolute;
    right:170px;
    top:190px;
  }
  h2{
    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
  }
  .card{
    font-family: 'Glory';
    font-style: normal;
  }
  .link{
    
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    color: #046ECF;

  }
  #offer{
    padding-bottom:40px;
  }
  .section2{
    padding:80px;
    background-color: #F9F9F9;

  }
  .section4{
    padding:80px;
    background-color: #F9F9F9;

  }
  #contact{
        height: 295px;
    padding-left: 295.75634765625px;
  }
  #talk{
    
    width: 416px;
    height: 54px;

    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
    padding-top:60px;
    /* identical to box height */


    color: #051A30;
  }
  #insta{

    padding-top:60px;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    /* identical to box height */
    a{
      padding-left:24px;
      text-decoration:none;
      color:black;
    }


    color: #051A30;

  }
  #mail{

    font-family: 'Glory';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    /* identical to box height */


    color: #051A30;

    a{
      padding-left:24px;
      text-decoration:none;
      color:black;
    }
  }
  .titulo{
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
  }
  .card-body{
    padding: 40px;
  }
  .texto{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}

@media(max-width: 768px){
  
  #tittle{
    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    padding-left:16px;
    padding-top:50px;
  }
  #comm{
    width:99vw;
    height: 250px;
  }
  h2{
    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
  }
  .card{
    font-family: 'Glory';
    font-style: normal;
  }
  .link{
    
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    color: #046ECF;

  }
  
  #contact{
    height: 230px;
  padding-left: 55.5px;
  }
  #talk{

  height: 54px;

  font-family: 'Glory';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 54px;
  padding-top:60px;
  padding-left:16px;
  /* identical to box height */


  color: #051A30;
  }
  #insta{

  padding-top:60px;
  font-family: 'Glory';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  /* identical to box height */
  a{
    padding-left:24px;
    text-decoration:none;
    color:black;
  }


  color: #051A30;

  }
  #instaPhoto{

    padding-left:16px;
    
  }
  #mailPhoto{

    padding-left:16px;
    
  }
  #mail{

  font-family: 'Glory';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  /* identical to box height */


  color: #051A30;

    a{
      padding-left:24px;
      text-decoration:none;
      color:black;
    }
  }
  .section1{
    width:99vw;
    
  }
  .section2{
    padding-top:16px;
    width:102vw;
    
    background-color: #F9F9F9;
  }
  .section4{
    padding-top:32px;
    width:102vw;
    
    background-color: #F9F9F9;
  }
  .carta1{
    padding-left:30px;
  }
  .carta2{
    padding-left:30px;
  }
  .titulo{
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
  }
  .card-body{
    padding: 40px;
  }
  .texto{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}
align-items: center;
`