import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './views/Home';
import Contact from './views/Contact';
import Consulting from './views/Consulting';
import Core from './views/Core';
import Footer from './components/Footer';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/consulting",
      element: <Consulting />,
    },
    {
      path: "/core",
      element: <Core />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
  ]);
  return (
    <>
    <div className='app' style={{minHeight: "calc(100vh - 50px)"}}>
      <Navbar/>
      
    <RouterProvider router={router} />
      {/* <Route path="/consulting" render={(props) => <AuthLayout {...props} />} /> */}
    </div>
      <Footer/>
    </>
  );
}

export default App;
