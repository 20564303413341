import foto1 from '../assets/app.png';
import foto2 from '../assets/cloud.png';
import foto3 from '../assets/firewall.png';
import foto4 from '../assets/idea.png';
import foto5 from '../assets/optimization.png';
import foto6 from '../assets/protection.png';
import foto7 from '../assets/structure.png';

var services = [
    {
        id:1,
        name:"Asesoramiento y consultoría tecnológica",
        img: foto7,
        text:"¿Está buscando llevar su negocio al siguiente nivel? En el mundo empresarial actual, la innovación y la tecnología son fundamentales para destacar. Alpha Conduit es la solución que necesita. Como expertos en consultoría y asesoría informática, estamos comprometidos en ayudar a nuestros clientes a superar sus desafíos empresariales más importantes a través de metodologías ágiles y técnicas personalizadas para cada proyecto. ¿Está listo para transformar su empresa con nuestro enfoque innovador y tecnológico? ¡Contáctenos hoy mismo!"
    },
    {
        id:2,
        name:"Ingeniería de software",
        img: foto5,
        text:"En Alpha Conduit, entendemos que cada empresa es única y tiene necesidades específicas. Por eso, nuestro servicio de Desarrollo se adapta a sus requerimientos. Nuestros profesionales altamente capacitados trabajan con metodologías ágiles o clásicas, para garantizar tiempos de entrega precisos en función de la complejidad del proyecto, siempre manteniendo un enfoque tecnológico innovador. Creemos que la transparencia y la comunicación son clave para lograr el éxito, por lo que trabajamos en estrecha colaboración con nuestros clientes, integrando sus ideas y necesidades en cada paso del proceso. En Alpha Conduit, su satisfacción es nuestra prioridad."
    },
    {
        id:3,
        name:"Diseño de UX/UI",
        img: foto1,
        text:"En Alpha Conduit, contamos con un equipo de expertos en mejorar la experiencia que los clientes ofrecen a través de sus plataformas digitales. Nos especializamos en diseñar páginas web empresariales, tiendas virtuales y sistemas personalizados, brindamos asesoramiento personalizado para cada proyecto, considerando el mercado objetivo y los potenciales clientes, para asi lograr resultados reales y aumentar las productividad de tu negocio."
    },
    {
        id:4,
        name:"Ciencia de los datos",
        img: foto4,
        text:"En Alpha Conduit, nuestro objetivo es empoderar a las empresas de todos los tamaños aprovechando el poder de los datos para tomar decisiones informadas y obtener una ventaja competitiva en el mercado. Nuestro equipo de científicos y analistas de datos se dedica a ayudar a las empresas a extraer información valiosa, optimizar procesos e impulsar el crecimiento mediante el uso estratégico de los datos."
    },
    {
        id:5,
        name:"Seguridad cibernética",
        img: foto3,
        text:"En Alpha Conduit, contamos con amplia experiencia en el área de ciberseguridad. No solo nos enfocamos en analizar vulnerabilidades en redes, equipos físicos y brechas en seguridad informática, sino que también desarrollamos planes de mitigación para minimizar los riesgos asociados a estas vulnerabilidades. Además, revisamos constantemente la seguridad de la información para detectar posibles amenazas y vulnerabilidades, y diseñamos procedimientos y rutinas que proporcionan los medios necesarios para prevenir la exposición o compromiso de información y procesos críticos. Gracias a nuestro conocimiento y experiencia en esta área, podemos ayudar a nuestros clientes a implementar soluciones de seguridad robustas y efectivas, que protejan su información y aseguren la continuidad de sus operaciones empresariales."
    },
    {
        id:6,
        name:"Monitoreo y soporte técnico ",
        img: foto5,
        text:"La supervisión y el control de los activos digitales que componen el ecosistema de TI de una organización es una actividad fundamental. Su objetivo es comprender el comportamiento de la red de recursos tecnológicos que interactúan dentro de la empresa, para optimizar su desempeño y responder de manera rápida ante cualquier posible falla. En Alpha Conduit, ofrecemos servicios especializados en el monitoreo y control de los activos digitales de nuestros clientes, a través de herramientas y tecnologías avanzadas que nos permiten identificar y corregir problemas de manera oportuna, evitando así interrupciones en los procesos críticos y garantizando el funcionamiento óptimo de la infraestructura de TI."
    },
    {
        id:7,
        name:"TI/plataforma en la nube",
        img: foto2,
        text:"En Alpha Conduit, somos expertos en la administración de infraestructuras IT, tanto en entornos on-premise como en la nube, incluyendo Google Cloud, AWS y Azure. Entendemos que la infraestructura TI es fundamental para el funcionamiento de las tareas y comunicaciones en cualquier compañía, así como para el desarrollo de estrategias de negocios y sistemas de información. Gracias a nuestra amplia experiencia en este campo, podemos ayudar a nuestros clientes a implementar soluciones eficientes y ágiles para la comunicación con los clientes y el funcionamiento de sus operaciones empresariales."
    },
    {
        id:8,
        name:"Sistemas de seguridad",
        img: foto6,
        text:"En Alpha Conduit, comprendemos la importancia de mantener su hogar y negocio seguros y protegidos. Es por eso que ofrecemos un servicio de instalación de seguridad de vanguardia diseñado para satisfacer sus necesidades específicas. Ya sea que esté buscando proteger a su familia y propiedad o salvaguardar los activos de su negocio, lo tenemos cubierto."
    }
]
export default services;