import React, { useState,useRef,useEffect  } from 'react'
import styled from 'styled-components'
import BurguerButton from './BurguerButton'
import wormhole from '../assets/wormhole.png'
import dictionary from '../data/dictionary'
function Navbar() {

  const [clicked, setClicked] = useState(false)
  const [language, setLanguage] = useState('ES')
  const [text, setText] = useState(false)
  const handleClick = () => {
    //cuando esta true lo pasa a false y vice versa
    setClicked(!clicked)
  }
  const handleLanguage = (item) => {
    //para marcar el lenguaje que se va a usar en el sistema
    setLanguage(item)
    localStorage.setItem('language', item);
    window.location = window.location.href;
  }
  const handleUrl = () => {
    window.location = "/";
  }
  const handleContact = () => {
    window.location = "/contact";
  }
  const [phone, setPhone] = React.useState();
  // Add a second state variable "height" and default it to the current window height

  React.useEffect(() => {
    if (window.innerWidth > 1000) {
      setPhone(0);
  }else{
    setPhone(1);
  }})
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (localStorage.language == 'ES') {
      setText(dictionary.es_ES)
    }else{
      setText(dictionary.en_US)
    }
  });
  return (
    <>
      <NavContainer> 
        <div>
            <div id="alpha">
            {/* <img  src={wormhole} alt="" /> */}
            <h2 role='button' onClick={handleUrl}><img id="worm" src={wormhole} alt="" /> ALPHA <br /><span>CONDUIT</span></h2>
            </div>
        </div>
        <div style={{paddingLeft:'4%'}}  className={`links ${phone ? clicked ? 'active' : '' : '' }`}>
          <a onClick={handleClick} href="/core">Alpha Core</a>
          <a onClick={handleClick} href="/consulting">Alpha Consulting</a>
        </div>
        <div style={{position:'absolute',right:'80px'}}>
          <div>
          <a className='lenguaje' onClick={() => handleLanguage('EN')} role='button'>EN</a>

          <a className='lenguaje' onClick={() => handleLanguage('ES')} role='button'>ES</a>
        
            <button onClick={handleContact} className='btn' id='contact'><span id="texto">{text.contact}</span></button>
          </div>
        </div>
        <div className='burguer' style={{paddingRight:'5%'}}>
          <BurguerButton clicked={clicked} handleClick={handleClick} />
        </div>
        <BgDiv className={`initial ${clicked ? ' active' : ''}`}></BgDiv>
      </NavContainer>
    </>
  )
}

export default Navbar

const NavContainer = styled.nav`
  @media(max-width: 768px){
    .lenguaje{
      
    }
    height: 72px;
    h2{
    color: black;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 300;
    font-size:22px;
    span{
      font-weight: bold;
    font-size:10px;
    letter-spacing: .3rem;
      color:#00C3FF
    }
  }
  br {
    display: block; /* makes it have a width */
    content: ""; /* clears default height */
    margin-top:-16px;
}
    #contact{
      background-color: #046ECF;
      color: white;
      width:100px;
      height:55px;
      border-radius:2px
      font-family: 'Glory';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    #alpha{
      padding-left:45px;
      padding-top:15px;
    }
    #worm{
      position: absolute;
      left: 16px;
      top: 28px;
      width: 19.35px;
      height: 24px;
    }
    justify-content:space-between;
  }
  @media(min-width: 768px){
    height: 120px;
    h2{
      color: black;
      font-weight: 300;
      font-size:47px;
      span{
        font-weight: bold;
      font-size:25px;
      letter-spacing: .3rem;
        color:#00C3FF
      }
    }
    br {
      display: block; /* makes it have a width */
      content: ""; /* clears default height */
      margin-top:-30px;
  }
  #contact{
    background-color: #046ECF;
    color: white;
    width:137px;
    height:40px;
    border-radius:2px
    font-family: 'Glory';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
    #alpha{
      padding-left:140px
    }
    #worm{
      position: absolute;
      left: 80px;
      top: 28px;
      width: 48.85px;
      height: 64px;
    }
  }
box-shadow: 0px 4px 15px rgba(129, 127, 127, 0.25);
border-radius: 2px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  a{
    color: black;
    text-decoration: none;
    margin-right: 1rem;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .links{
    position: absolute;
    top: -700px;
    right: -2000px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all .5s ease;
    a{
      color: white;
      font-size: 2rem;
      display: block;
    }
    @media(min-width: 768px){
      position: initial;
      margin: 0;
      a{
        font-size: 1rem;
        color: black;
        display: inline;
      }
      display: block;
    }
  }
  .links.active{
    width: 100%;
    display: block;
    position: absolute;
    margin-left: auto;
    z-index: 2;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    a{
      font-size: 2rem;
      margin-top: 1rem;
      color: white;
    }
  }
  .burguer{
    @media(min-width: 768px){
      display: none;
    }
  }
`

const BgDiv = styled.div`

@media(max-width: 768px){
  background-color:#046ECF;
  position: absolute;
  top: -1000px;
  right: -1000px;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all .6s ease ;
  
  &.active{
    border-radius: 0 0 0 80%;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
`