import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ServicesSection from './ServicesSection'
import photo2 from '../assets/consulting.jpg'
import dictionary from '../data/dictionary'
function Consulting() {

  const [clicked, setClicked] = useState(false)
  const [text, setText] = useState(false)
  const handleClick = () => {
    //cuando esta true lo pasa a false y vice versa
    setClicked(!clicked)
  }
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (localStorage.language == 'ES') {
      setText(dictionary.es_ES)
    }else{
      setText(dictionary.en_US)
    }
  });
  return (
    <>
      <ConsultingContainer> 
        <div id="consulting">
            <img id="headerImg" src={photo2} alt="" />
            <div className="centered"><h2 id="tittle">Alpha Consulting</h2></div>
        </div>
                  <div className="row sectionServ" >
                    <div className="col">
                        <h2 id="Tserv">{text.services}</h2>
                        <p className='text'>{text.servicesTextFirst} </p>
                        <p className='text'>{text.servicesTextSecond}</p>
                    </div>
                  </div>
        <ServicesSection/>
      </ConsultingContainer>
    </>
  )
}

export default Consulting

const ConsultingContainer = styled.div`

@media(min-width: 768px){
  .sectionServ{
    padding:80px;
    width:100vh;
  }
  #consulting{
    
    position: relative;
    text-align: center;
      background: black;
      overflow: hidden;
  }
  #tittle{
      
  font-family: 'Glory';
  font-style: normal;
  font-weight: 500;
  font-size: 78px;
  line-height: 87px;
  text-align: center;
  
  color: #FFFFFF;
  
  }
  #Tserv{
      
  font-family: 'Glory';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  /* identical to box height */
  
  
  color: #051A30;
  }
  .text{
      
  font-family: 'Glory';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  
  
  color: #000000;
  }
  #headerImg{
      min-height: 200px;
      width: 100vw;
      height: 347px;
     object-fit: cover;
     opacity: 0.5;
  }
  
  .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}
@media(max-width: 768px){

  .sectionServ{
    padding:16px;
    width:100vw;
  }
  #consulting{
    
    position: relative;
    text-align: center;
      background: black;
      overflow: hidden;
  }
  #tittle{
      
  font-family: 'Glory';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  
  color: #FFFFFF;
  
  }
  #Tserv{
      padding-top:16px;
font-family: 'Glory';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 36px;
/* identical to box height */

text-align: center;
  
  
  color: #051A30;
  }
  .text{
    padding-top:16px;
  font-family: 'Glory';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  
  
  color: #000000;
  }
  #headerImg{
      min-height: 200px;
      width: 100vw;
      height: 250px;
     object-fit: cover;
     opacity: 0.5;
  }
  
  .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}
`