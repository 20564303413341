import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import photo1 from '../assets/core.jpg'
import mockup from '../assets/mockups.png'
import icon1 from '../assets/list.png'
import icon2 from '../assets/RRHH.png'
import icon3 from '../assets/Finances.png'
import icon4 from '../assets/Report.png'
import icon5 from '../assets/CRM.png'
import icon6 from '../assets/POS.png'
import dictionary from '../data/dictionary'
function Core() {

  const [text, setText] = useState(false)
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (localStorage.language == 'ES') {
      setText(dictionary.es_ES)
    }else{
      setText(dictionary.en_US)
    }
  });
  return (
    <>
      <ConsultingContainer> 
        <div id="consulting">
            <img id="headerImg" src={photo1} alt="" />
            <div className="centered"><h2 id="tittle">Alpha Core</h2></div>
        </div>
        <div className="row section1" >
            <div className="col mockImg" >
                <img id="mockImg"  src={mockup} alt="" />
            </div>
            <div className="col mockText" >
                <h2 id="Tserv">{text.what}</h2>
                <p className='text'>
                  {text.coreText}
                </p>
                <a href="#" className='link'>{text.download}</a>
            </div>
        </div>
        <div className="row section2">
            <h2 id="Tcore">{text.coreTittle}</h2>
            
          <div className="col-sm-4 mb-3 mb-sm-0 carta">
            <div className="card">
            <div className="card-header"><img className='icon' src={icon1} alt="" /></div>
              <div className="card-body">
                <h4 className="card-title titulo">{text.coreSubTittle1}</h4>
                <p className="card-text texto">
                  {text.coreSubText1}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 carta" >
            <div className="card">
            <div className="card-header"><img className='icon' src={icon2} alt="" /></div>
              <div className="card-body">
                <h4 className="card-title titulo">{text.coreSubTittle2}</h4>
                <p className="card-text texto">
                  {text.coreSubText2}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0 carta">
            <div className="card">
            <div className="card-header"><img className='icon' src={icon3} alt="" /></div>
              <div className="card-body">
                <h4 className="card-title titulo">{text.coreSubTittle3}</h4>
                <p className="card-text texto">
                  {text.coreSubText3}
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-sm-4 carta" >
            <div className="card">
            <div className="card-header"><img className='icon' src={icon4} alt="" /></div>
              <div className="card-body">
                <h4 className="card-title titulo">{text.coreSubTittle4}</h4>
                <p className="card-text texto">
                  {text.coreSubText4}
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0 carta">
            <div className="card">
            <div className="card-header"><img className='icon' src={icon5} alt="" /></div>
              <div className="card-body">
                <h4 className="card-title titulo">{text.coreSubTittle5}</h4>
                <p className="card-text texto">
                  {text.coreSubText5}
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-sm-4 carta" >
            <div className="card">
            <div className="card-header"><img className='icon' src={icon6} alt="" /></div>
              <div className="card-body">
                <h4 className="card-title titulo">{text.coreSubTittle6}</h4>
                <p className="card-text texto">
                  {text.coreSubText6}
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </ConsultingContainer>
    </>
  )
}

export default Core

const ConsultingContainer = styled.div`

@media(min-width: 768px){

    #consulting{
    
        position: relative;
        text-align: center;
          background: black;
          overflow: hidden;
      }
      .carta{
          padding-bottom:16px;
      }
      .card-body{
      
          padding:40px;
      }
      .texto{
          height:93px;
          font-family: 'Glory';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          /* or 125% */
          
          
          color: #000000;
          
      }
      .card-header{
          background: #ADD0F1;
      }
      .titulo{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 27px;
      color: #000000;
      }
      .icon{
          padding:28px 40px;
      }
      #Tcore{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 54px;
      /* identical to box height */
      
      text-align: center;
      
      color: #051A30;
      }
      .section1{
          padding:80px;
          width:99vw;
      }
      .section2{
          padding:80px;
          width:99vw;
          background: #F9F9F9;
      }
      .mockImg{
          text-align:right;
      }
      .mockText{
          padding:110px;
      }
      #tittle{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 78px;
      line-height: 87px;
      text-align: center;
      
      color: #FFFFFF;
      
      }
      .link{
          text-decoration:none;
      font-family: 'Glory';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      
      
      /* clickable */
      
      color: #046ECF;
      
      }
      #Tserv{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 54px;
      /* identical to box height */
      
      
      color: #051A30;
      }
      .text{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */
      
      
      color: #000000;
      }
      #headerImg{
          min-height: 200px;
          width: 100vw;
          height: 347px;
         object-fit: cover;
         opacity: 0.5;
      }
      
      .centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
}

@media(max-width: 768px){

    #consulting{
    
        position: relative;
        text-align: center;
          background: black;
          overflow: hidden;
      }
      .carta{
          padding-bottom:16px;
      }
      .card-body{
      
          padding:27px;
      }
      .texto{
        
          font-family: 'Glory';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          /* or 125% */
          
          
          color: #000000;
          
      }
      .card-header{
          background: #ADD0F1;
      }
      .titulo{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 27px;
      color: #000000;
      }
      .icon{
          padding:28px 40px;
      }
      #Tcore{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 54px;
      /* identical to box height */
      
      text-align: center;
      
      color: #051A30;
      }
      .section1{
        padding:16px;
          width:99vw;
      }
      
      .section2{
        width:103vw;
          padding-left:16px;
          background: #F9F9F9;
      }
      #mockImg{
        width: 343px;
        padding:16px;
        height: 322px;
      }
      .mockImg{
        order: 2;
      }
      .mockText{
        order: 1;
          padding:10px;
      }
      #tittle{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 87px;
      text-align: center;
      
      color: #FFFFFF;
      
      }
      .link{
          text-decoration:none;
      font-family: 'Glory';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      
      
      /* clickable */
      
      color: #046ECF;
      
      }
      #Tserv{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 54px;
      /* identical to box height */
      
      
      color: #051A30;
      }
      .text{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */
      
      
      color: #000000;
      }
      #headerImg{
          min-height: 200px;
          width: 100vw;
          height: 205px;
         object-fit: cover;
         opacity: 0.5;
      }
      
      .centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
}
`