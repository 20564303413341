import React, { useState, useEffect }  from 'react'
import  Axios  from 'axios'
import styled from 'styled-components'
import photo1 from '../assets/contact.jpg'
import footImage from '../assets/footer.png'
import icon1 from '../assets/instagram.png'
import icon2 from '../assets/mail.png'
import dictionary from '../data/dictionary'
import * as config from '../config';
function Contact() {

  const [send, setSend] = useState(false)
  const [text, setText] = useState(false)
  const [product, setProduct] = useState({
    Nombre:'',
    Email:'',
    Mensaje:''
  });
  const [post, setPost] = React.useState(null);
  const baseURL = config.BASE_URL;
  const handleClick = () => {
    //cuando esta true lo pasa a false y vice versa
      console.log(product+"probando")
    Axios
      .post(baseURL+'/contact', {
        Name: product.Nombre,
        Email: product.Email,
        Message:product.Mensaje
      })
      .then((response) => {
        console.log(response.data);
      });
      if (product.Nombre != '' && product.Email != '' && product.Mensaje != '') {
        setSend(!send)
      }
  }

  const handleChange = (e, index) => {
    const re = /\d+(,\d+)?/;
    const { id, value, inputMode, name } = e.target

    if (inputMode === 'numeric') {
        if (value === '' || re.test(value)) {
            setProduct({
                ...product,
                [id]: value
            })
        }
    } else {
        setProduct({
            ...product,
            [id]: value
        })
    }
    
}
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (localStorage.language == 'ES') {
      setText(dictionary.es_ES)
    }else{
      setText(dictionary.en_US)
    }
  });
  return (
    <>
      <ConsultingContainer> 
        <div id="consulting">
            <img id="headerImg" src={photo1} alt="" />
            <div className="centered"><h2 id="tittle">{text.footer}</h2></div>
        </div>
        <div className="row" >
            <div className="col section1 mockImg" >
                <img id="mockImg"  src={footImage} alt="" />
                <div id="textoIconos">
                  <p id="insta">
                  <img id="instaPhoto" src={icon1} alt="" />
                    <a href="https://instagram.com/alphaconduit" target="_blank">@alphaconduit</a>
                  </p>
                  <p id="mail">
                  <img id="mailPhoto" src={icon2} alt="" />
                    <a href="mailto:hola@alphaconduit.tech">hola@alphaconduit.tech</a>
                  </p>
                </div>
            </div>
            <div className="col section2" >
              {send ?
                <>
                <h1 id="thanks">{text.thanks} <br />
                  <span>
                    {text.soon}
                  </span>
                </h1>
                
                </> :
                <>
                  <h1>{text.contactTIttle}</h1>
                    <form>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">{text.name}</label>
                      
                      <input type="text"
                        className="form-control form-input"
                        id="Nombre"
                        aria-describedby="nombreHelp"
                        value={product.Nombre}
                        onChange={handleChange}
                      />
                      <div id="nombreHelp" className="form-text">{text.nameInfo}</div>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">Email</label>
                      <input type="email" className="form-control form-input"
                        id="Email"
                        aria-describedby="emailHelp"
                        value={product.Email}
                        onChange={handleChange}
                      />
                      <div id="emailHelp" className="form-text">{text.emailInfo}</div>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">{text.message}</label>
                      <textarea type="text" className="form-control form-input"
                        id="Mensaje"
                        aria-describedby="mensajeHelp"
                        value={product.Mensaje}
                        onChange={handleChange}
                      />
                      <div id="mensajeHelp" className="form-text">{text.messageInfo}</div>
                    </div>
                    <button disabled={product.Nombre == '' || product.Email == '' || product.Mensaje == ''} type="button" className="btn" id='submitBtn' onClick={handleClick}>{text.send}</button>
                  </form>
                </>
              }
            </div>
        </div>
      </ConsultingContainer>
    </>
  )
}

export default Contact

const ConsultingContainer = styled.div`

@media(min-width: 768px){
  #thanks{
    padding-top: 195px;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
    text-align: center;

    color: #051A30;
    span{
      
    font-family: 'Glory';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35.84px;
    text-align: center;

    color: #051A30;
    }
  }
    .form-input{
      width: 352px;
    }
    #submitBtn{
        background-color: #046ECF;
        color: white;
        width:137px;
        height:40px;
        border-radius:2px
        font-family: 'Glory';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      
    }
    #insta{

        padding-top:60px;
        font-family: 'Glory';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        /* identical to box height */
        a{
          padding-left:24px;
          text-decoration:none;
          color:black;
        }
    
    
        color: #051A30;
    
      }
      #mail{
        padding-left:82px;
        font-family: 'Glory';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        /* identical to box height */
    
    
        color: #051A30;
    
        a{
          padding-left:24px;
          text-decoration:none;
          color:black;
        }
      }
    #consulting{
    
        position: relative;
        text-align: center;
          background: black;
          overflow: hidden;
      }
      .icon{
          padding:28px 40px;
      }
      .row{
        width:99vw;     
      }
      .section1{
          padding:80px;
          background: #F9F9F9;
      }
      .section2{
        padding:80px 40px;
          background: #fff;
      }
      .mockImg{
          text-align:center;
      }
      #mockImg{

        text-align:center;
        width: 276.78px;
        height: 278.87px;
      }
      .mockText{
          padding:110px;
      }
      #tittle{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 78px;
      line-height: 87px;
      text-align: center;
      
      color: #FFFFFF;
      
      }
      .link{
          text-decoration:none;
      font-family: 'Glory';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      
      
      /* clickable */
      
      color: #046ECF;
      
      }
      .text{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */
      
      
      color: #000000;
      }
      #headerImg{
          min-height: 200px;
          width: 100vw;
          height: 347px;
         object-fit: cover;
         opacity: 0.5;
      }
      
      .centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
}

@media(max-width: 768px){

  #thanks{
    padding-top: 16px;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 35.84px;
    text-align: center;

    color: #051A30;
    span{
      
    font-family: 'Glory';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26.88px;
    text-align: center;

    color: #051A30;
    }
  }
  .form-input{
      
    width: 103%;
        }
        
    #submitBtn{
      background-color: #046ECF;
      color: white;
      width:137px;
      height:40px;
      border-radius:2px
      font-family: 'Glory';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    
  }
      .row{
        width:102vw;     
      }
    #insta{

        padding-top:60px;
        font-family: 'Glory';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        /* identical to box height */
        a{
          padding-left:24px;
          text-decoration:none;
          color:black;
        }
        
        
        color: #051A30;
        
        }
        #instaPhoto{
        
          padding-left:16px;
          
        }
        #mailPhoto{
        
          padding-left:16px;
          
        }
        #mail{
        
        font-family: 'Glory';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        /* identical to box height */
        
        
        color: #051A30;
        
        a{
          padding-left:24px;
          text-decoration:none;
          color:black;
        }
        }
    #consulting{
    
        position: relative;
        text-align: center;
          background: black;
          overflow: hidden;
      }
      .section1{
        padding:16px;
          background: #F9F9F9;
      }
      #textoIconos{
        
        text-align:left;
      }
      .section2{
        padding:16px;
          background: #fff;
      }
      #mockImg{
        padding:16px;
        width: 276.78px;
        height: 278.87px;
      }
      .mockImg{
        text-align:center;
        order: 2;
      }
      .mockText{
        order: 1;
          padding:10px;
      }
      #tittle{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 87px;
      text-align: center;
      
      color: #FFFFFF;
      
      }
      .link{
          text-decoration:none;
      font-family: 'Glory';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      
      
      /* clickable */
      
      color: #046ECF;
      
      }
      .text{
          
      font-family: 'Glory';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */
      
      
      color: #000000;
      }
      #headerImg{
          min-height: 200px;
          width: 100vw;
          height: 205px;
         object-fit: cover;
         opacity: 0.5;
      }
      
      .centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
}
`