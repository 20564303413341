import foto1 from '../assets/app.png';
import foto2 from '../assets/cloud.png';
import foto3 from '../assets/firewall.png';
import foto4 from '../assets/idea.png';
import foto5 from '../assets/optimization.png';
import foto6 from '../assets/protection.png';
import foto7 from '../assets/structure.png';

var servicesEN = [
    {
        id:1,
        name:"Tech consulting and advisory",
        img: foto7,
        text:"Are you looking to take your business to the next level? In today's business world, innovation and technology are essential to stand out. Alpha Conduit is the solution you need. As IT consulting and advisory experts, we are committed to helping our clients overcome their most important business challenges through agile methodologies and customized techniques for each project. Are you ready to transform your business with our innovative and technological approach? Contact us today!"
    },
    {
        id:2,
        name:"Software engineering",
        img: foto5,
        text:"At Alpha Conduit, we understand that each business is unique and has specific needs. For this reason, our Development service adapts to your requirements. Our highly trained professionals work with agile or classic methodologies, to guarantee precise delivery times based on the complexity of the project, always maintaining an innovative technological approach. We believe that transparency and communication are key to success, so we work closely with our clients, integrating their ideas and needs at every step of the process. At Alpha Conduit, your satisfaction is our priority."
    },
    {
        id:3,
        name:"UX/UI Design",
        img: foto1,
        text:"At Alpha Conduit, we have a team of experts in improving the experience that customers offer through their digital platforms. We specialize in designing business web pages, virtual stores and personalized systems, we provide personalized advice for each project, considering the target market and potential clients, in order to achieve real results and increase the productivity of your business."
    },
    {
        id:4,
        name:"Data Science",
        img: foto4,
        text:"At Alpha conduit we aim to empower businesses of all sizes by harnessing the power of data to make informed decisions and gain a competitive edge in the market. Our team of  data scientists and analysts are dedicated to helping businesses extract valuable insights, optimize processes, and drive growth through the strategic use of data."
    },
    {
        id:5,
        name:"Cybersecurity",
        img: foto3,
        text:"At Alpha Conduit, we have extensive experience in the area of ​​cybersecurity. We not only focus on analyzing vulnerabilities in networks, physical equipment and information security breaches, but we also develop mitigation plans to minimize the risks associated with these vulnerabilities. In addition, we constantly review information security to detect potential threats and vulnerabilities, and we design procedures and routines that provide the necessary means to prevent the exposure or compromise of critical information and processes. Thanks to our knowledge and experience in this area, we can help our clients implement robust and effective security solutions that protect their information and ensure the continuity of their business operations."
    },
    {
        id:6,
        name:"Monitoring & Tech support ",
        img: foto5,
        text:"The supervision and control of the digital assets that make up the IT ecosystem of an organization is a fundamental activity. Its objective is to understand the behavior of the network of technological resources that interact within the company, to optimize its performance and respond quickly to any possible failure. At Alpha Conduit, we offer specialized services in the monitoring and control of our clients' digital assets, through advanced tools and technologies that allow us to identify and correct problems in a timely manner, thus avoiding interruptions in critical processes and guaranteeing optimal operation. of the IT infrastructure."
    },
    {
        id:7,
        name:"IT/Cloud Platform",
        img: foto2,
        text:"At Alpha Conduit, we are experts in managing IT infrastructures, both in on-premises and cloud environments, including Google Cloud, AWS, and Azure. We understand that IT infrastructure is essential for the operation of tasks and communications in any company, as well as for the development of business strategies and information systems. Thanks to our extensive experience in this field, we are able to help our clients implement efficient and agile solutions for communicating with clients and running their business operations."
    },
    {
        id:8,
        name:"Security systems",
        img: foto6,
        text:"At Alpha Conduit, we understand the importance of keeping your home and business safe and secure. That's why we offer a state-of-the-art security installation service tailored to meet your specific needs. Whether you're looking to protect your family and property or safeguard your business assets, we have you covered."
    }
]
export default servicesEN;