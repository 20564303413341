import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import services from '../data/services'
import servicesEN from '../data/servicesEN'
import dictionary from '../data/dictionary'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";

function ServicesSection() {
  const [phone, setPhone] = React.useState();
  const [servicesText, setServicesText] = React.useState();
  const [text, setText] = useState(false)
  const [view, setView] = useState(false)
  // Add a second state variable "height" and default it to the current window height

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (localStorage.language == 'ES') {
      setServicesText(services)
      setView(window.location.pathname);
      setText(dictionary.es_ES)
    }else{
      setText(dictionary.en_US)
      setView(window.location.pathname);
      setServicesText(servicesEN)
    }
    console.log(view);
  });
  React.useEffect(() => {
    if (window.innerWidth > 1000) {
      setPhone(0);
  }else{
    setPhone(1);
  }})
  const [itemSelected, setItemSelected] = useState({})
  const [change, setChange] = useState(false)
  const handleClick = (item) => {
    setItemSelected(item)
    setChange(true)
  }
  
  return (
    <Services>
      {view == '/' ?
      <h2 id="Tservi" style={{textAlign:'center'}}>{text.services}</h2> :
      <h2 id="Tservi" style={{textAlign:'center'}}></h2> }
      <div style={{paddingTop: view == '/' ? '40px' : ''}} className="row  justify-content-end">
        {phone == 1 ?
        <>
        
      <Swiper
      slidesPerView={2}
      spaceBetween={0}
      freeMode={true}
      modules={[FreeMode, Pagination]}
      className="mySwiper"
    >
        
        {servicesText?.map((item) => {
          return (
            <>
            <SwiperSlide>
            <div style={{paddingTop:'10px'}}  className={`col mb-3 mb-sm-0 carta ${change == 1 ? itemSelected.id == item.id ? 'important' : 'notImportant' : 'important'}`}>
              <a style={{
    textDecoration: 'none',color:'black'}} href="javascript:void(0);" role="button" onClick={() => handleClick(item)}>
              <div className="card cosas border-light">
              <img className="card-img-top imagen" src={item.img}  alt="" />
                <div >
                  <h5>{item.name}</h5>
                </div>
              </div>
              </a>
            </div>

            </SwiperSlide>
            
            </>
          )
        })}
        </Swiper>
            <div >
                  {itemSelected.text} 
              </div>
        
        </>
        : 
        <>
        {servicesText?.map((item) => {
          return (
            <>
            <div className={`col mb-3 mb-sm-0 carta ${change == 1 ? itemSelected.id == item.id ? 'important' : 'notImportant' : 'important'}`}>
              <a style={{
    textDecoration: 'none',color:'black'}} href="javascript:void(0);" role="button" onClick={() => handleClick(item)}>
              <div className="card cosas border-light">
              <img className="card-img-top imagen" src={item.img}  alt="" />
                <div >
                  <h5>{item.name}</h5>
                </div>
              </div>
              </a>
              <div id="textoDesc" className={`${itemSelected.id == item.id ? 'active' : 'notActive'}
              ${itemSelected.id >4 ? 'right' : 'left'}`}>
                  {item.text} 
              </div>
            </div>

            
            </>
          )
        })}
        </>
        }
      </div> 
    </Services>
  )
}

export default ServicesSection

const Services = styled.div`
.important{

}
.notImportant{
  
background: #FFFFFF;
opacity: 0.2;
box-shadow: 0px 4px 15px rgba(129, 127, 127, 0.25);
}
@media(min-width: 768px){
  padding:80px;
  .right{
    float:right;
  }
  
.active{
  display:block;
}
.notActive{
  display: none;
}
#textoDesc{
  width: 631px;
  padding-top:89px;
  font-family: 'Glory';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  
  color: #000000;
}
}

@media(max-width: 768px){
  padding:32px;
  .right{
    float:center;
  }
  
  .active{
    display:inline;
  }
  .notActive{
    display: none;
  }
  #textoDesc{
    width: 100vw;
    padding-top:89px;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    
    color: #000000;
  }
}
#Tservi{
  
font-family: 'Glory';
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 54px;
/* identical to box height */

text-align: center;

}
.carta{
  width:146px;
}
align-items: center;
h5{
  
font-family: 'Glory';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;

text-align: center;
}
.cosas{
  align-items: center;
  padding: 8px;
  gap: 8px;
  height: 200px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(129, 127, 127, 0.25);
}
.imagen{
  
width: 120px;
height: 120px;
}
`