import React from 'react';
import styled from 'styled-components'

function Footer() {
  return (
    <FooterComponent>
    <div className="footer">
      <p id="textFooter">© 2023 AlphaConduit</p>
      </div>
    </FooterComponent>
  );

}
export default Footer
const FooterComponent = styled.div`
.footer {
    margin-top: auto;
    background-color: #364B55;
    height:50px;
    
font-family: 'Glory';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
color:white;
 }
 @media(min-width: 768px){
    #textFooter{
        text-align:right;
        padding-right:80px;
        padding-top:16px;
    }
 }
 @media(max-width: 768px){
    #textFooter{
        text-align:center;
        padding-top:16px;
    }
 }
`