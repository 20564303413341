var dictionary = 
    {
        en_US: {
            tittle:'The conduit to digital transformation',
            offer:'Our Offer',
            core:'Manage your company with certified quality software. Alpha Core offers the widest range of options to manage your business, no matter the size of it, developing solutions that allow the automation of processes and services.',
            consulting:'We provide appropriate technological and management solutions to each client, using agile tools and methodologies, guaranteeing the results of our work thanks to the responsibility and versatility in project management and planning',
            services:'Services',
            contact:'Contact us',
            footer:"Let's talk?",
            info:'Learn more',
            servicesTextFirst:'We provide appropriate technological and management solutions to each client, using agile tools and methodologies, guaranteeing the results of our work thanks to the responsibility and versatility in project management and planning.',
            servicesTextSecond:'Don’t hesitate on contacting us to learn more about our services!',
            what:'¿What does it do?',
            coreText:'Core offers you an information and management platform for your business. Robust, friendly and low cost. Designed to facilitate the transition from traditional business methodologies to new levels of process automation that are only possible thanks to the proper use of new technologies.',
            download:'Download our brochure',
            coreTittle:'Manage your company with certified quality software',
            coreSubTittle1:'Inventory control',
            coreSubText1:'Module in charge of keeping any inventory movement updated, automatically and in real time, each action carried out by the rest of the system will be reflected within the system',
            coreSubTittle2:'User management and HR',
            coreSubText2:'Module in charge of the administration of users registered in the business, at the same time providing levels of security and permissions for users who will interact directly with the system.',
            coreSubTittle3:'Finance',
            coreSubText3:'Group of tools that are responsible for keeping the financial information organized and available, which at the same time feeds the reporting module.',
            coreSubTittle4:'Dynamic reporting',
            coreSubText4:'Graphic representation of the historical and current state of the business, sales, expenses, grouped into categories, branches, items, customers, showing graphs that will improve the perspective of the business, helping decision making.',
            coreSubTittle5:'CRM',
            coreSubText5:'Customer Relationship Management Tool in charge of managing customer information, which allows customizing the user experience when making a sale',
            coreSubTittle6:'Point of sale',
            coreSubText6:'Sales interface in charge of intuitively displaying all the products, services and promotions available within the business network.',
            thanks:'thank you',
            soon:'We will contact you soon',
            contactTittle:'We want to bring your ideas to life',
            name:'Name',
            nameInfo:'Enter your name',
            emailInfo:'Enter your email',
            message:'Your message',
            messageInfo:'Enter the message you want to send',
            send:'Send'
        },
        es_ES: {
            tittle:'El conducto hacia la transformación digital',
            offer:'Nuestra oferta',
            core:'Gestiona tu empresa con software de calidad certificada. Alpha Core ofrece la más amplia gama de opciones para administrar su negocio, sin importar el tamaño del mismo, desarrollando soluciones que permitan la automatización de procesos y servicios.',
            consulting:'Aportamos soluciones tecnológicas y de gestión adecuadas a cada cliente, utilizando herramientas y metodologías ágiles, garantizando los resultados de nuestro trabajo gracias a la responsabilidad y versatilidad en la gestión y planificación de proyectos',
            services:'Servicios',
            contact:'Contactanos',
            footer:"Hablamos?",
            info:'Aprende mas',
            servicesTextFirst:'Aportamos soluciones tecnológicas y de gestión adecuadas a cada cliente, utilizando herramientas y metodologías ágiles, garantizando los resultados de nuestro trabajo gracias a la responsabilidad y versatilidad en la gestión y planificación de proyectos.',
            servicesTextSecond:'¡No dudes en contactarnos para conocer más sobre nuestros servicios!',
            what:'¿Qué hace?',
            coreText:'Core te ofrece una plataforma de información y gestión, para tu negocio. Robusta, amigable y de bajo costo. Pensada en facilitar la transición de metodologías tradicionales de negocio a los nuevos niveles de automatización de procesos que solo son posibles gracias al buen uso de las nuevas tecnologías.',
            download:'Descarga nuestro folleto',
            coreTittle:'Gestiona tu empresa con un software de calidad certificada',
            coreSubTittle1:'Control de inventario',
            coreSubText1:'Modulo encargado de mantener actualizado cualquier movimiento de inventario, de manera automática y en tiempo real, cada acción realizada por el resto del sistema se verá reflejada dentro del sistema',
            coreSubTittle2:'Control de usuarios y RRHH',
            coreSubText2:'Modulo encargado de la administracion de usuarios registrados en el negocio, al mismo tiempo proporcionando niveles de seguiridad y permisologuia para usuarios que interactuaran directamente con el sistema.',
            coreSubTittle3:'Finanzas',
            coreSubText3:'Grupo de herramientas que se encargan de mantener el registro organizado y disponibilizado de informacion financiera, que al mismo tiempo alimenta al modulo de reportes.',
            coreSubTittle4:'Reporteria dinámica',
            coreSubText4:'Representación gráfica del estado histórico y actual del negocio, ventas, gastos, agrupados en categorías , sucursales, artículos, clientes, mostrando graficas que mejoraran la perspectiva del negocio, ayudando a la toma de decisiones.',
            coreSubTittle5:'CRM',
            coreSubText5:'Customer Relationship Management - Gestión de las relaciones con clientes, Herramienta encargada de la gestion de informacion de clientes, lo que permite personalizar la experiencia del usuario al momento de realizar alguna venta',
            coreSubTittle6:'Punto de venta',
            coreSubText6:'Interfaz de ventas encargado de mostrar de manera intuitiva todos los productos, servicios y promociones disponibles dentro de la red de negocios.',
            thanks:'Gracias!',
            soon:'Luego nos pondremos en contacto contigo',
            contactTittle:'Queremos darle vida a tus ideas',
            name:'Nombre',
            nameInfo:'Ingrese su nombre',
            emailInfo:'Ingrese su correo electronico',
            message:'Tu mensaje',
            messageInfo:'Ingrese el mensaje que desea enviar',
            send:'Enviar'
        }
      }


export default dictionary;